<template>
  <section class="buildSystem">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <section class="title">
        <el-image :src="options.img1"></el-image>
        <p class="ch"><strong>构建助力飞多赢体系</strong></p>
        <div class="line"></div>
        <p class="en"><strong>Multi-win System</strong></p>
      </section>
      <el-row :gutter="30" class="main">
        <el-col :span="24">
          <div class="top"><div class="tit">关于一体化信息服务的优势</div></div>
          <p>信息系统的诞生就是为了解决信息孤岛，衍生到企业管理上，应该是把企业的采购、销售、库存、财务、行政办公、审批、绩效管理、项目管理、决策支持进行一体化的管理，使数据和业务审批都形成联动。这种曾经非常昂贵的一体化信息服务正在逐渐迈入更多的普通企业，然而由于很多企业尚未体验过真正的一体化系统，导致对于信息系统的管理仍停留在局部管理的阶段，格局并未完全打开。</p>
          <p>我们希望通过我们的努力，能够让更多的企业体验、尝试，最终能采用一体化系统进行企业管理，从而做信息化系统管理的主人，提高企业的精益化管理和收益。</p>
        </el-col>
      </el-row>
      <el-image :src="options.img5" class="img1"></el-image>
    </section>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  name: 'zhulifei',
  components: {
    imageBanner,
  },
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/05-助力飞.png'),
        slogan: require('../../assets/images/common/slogan.png'),
      },
      options: {
        img1: require('../../assets/images/2023/zhulifei/页面5.png'),
        img5: require('../../assets/images/2023/zhulifei/页面图-04.png'),
      }
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      // let id = (this.$route.query.id).toString()
      // switch (id) {
      //   case '1':
      //     this.options = this.$store.getters.getTeam1 && JSON.parse(JSON.stringify(this.$store.getters.getTeam1))
      //     break
      //   case '2':
      //     this.options = this.$store.getters.getTeam2 && JSON.parse(JSON.stringify(this.$store.getters.getTeam2))
      //     break
      //   case '3':
      //     this.options = this.$store.getters.getTeam3 && JSON.parse(JSON.stringify(this.$store.getters.getTeam3))
      //     break
      //   case '4':
      //     this.options = this.$store.getters.getTeam4 && JSON.parse(JSON.stringify(this.$store.getters.getTeam4))
      //     break
      //   default:
      //     this.options = this.$store.getters.getTeam1 && JSON.parse(JSON.stringify(this.$store.getters.getTeam1))
      //     break
      // }
    }
  }
}
</script>

<style scoped>
.buildSystem {background-color: #ecf0f5; padding-bottom: 1rem;}
.container {padding: 1rem 10%;}
.buildSystem .title {text-align: center; padding: 1rem 0 2rem;}
.buildSystem .title .el-image {width: auto;}
.buildSystem .ch, .buildSystem .en {font-size: 3rem}
.buildSystem .ch {color: #475f76}
.buildSystem .en {color: #ccc;}
.buildSystem .line {width: 40%; height: 2px; background-color: #ccc; margin: 0 auto;}
.container p {line-height: 2; text-indent: 2em;}
.buildSystem .main {padding: 0 0 2rem;}
.buildSystem .main .top {border-top: 4px solid #999; line-height: 2; text-indent: 2em; padding-bottom: 3rem;}
.buildSystem .main .tit {width: 60%; background-color: #ccc; border-radius: 0 0 2rem 0; font-weight: 600; font-size: 1.4rem;}
.buildSystem .img1 {padding: 0; width: 100%;}
</style>